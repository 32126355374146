<template>
  <v-app>
    <v-main class="bg-primary-gradient">
      <v-container fill-height>
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {}
</script>
